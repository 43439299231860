<template>
  <div
    class="card-input card-input_normal"
    :class="{
      'StripeElement--complete': isCompleted,
      'StripeElement--focus': isFocuced
    }">
    <input
      placeholder="JANE DOE"
      @focusin="toggleStripeClass(true, false)"
      @blur="toggleStripeClass(false, true)"
      @keyup="validateField">
  </div>
</template>

<script>
export default {
  data() {
    return {
      isFocuced: false,
      isCompleted: false,
      inputData: {}
    }
  },
  props: {
    fieldName: String
  },
  methods: {
    validateField(e) {
      this.inputData = {
        complete: e.target.value !== '',
        empty: e.target.value === '',
        elementType: this.fieldName
      }
      this.$emit('validateInput', this.inputData)
      this.$emit('setInputValue', {name: this.fieldName, value: e.target.value})
    },
    toggleStripeClass(focused, completed) {
      this.isFocuced = focused
      this.isCompleted = completed && this.inputData.complete
      if (focused) {
        // slow stripe bypass
        setTimeout(() => {
          this.$emit('resetScrollWindow')
        }, 30)
      } else {
        this.$emit('scrollWindow')
      }
    }
  }
}
</script>